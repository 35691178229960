<template>
    <seccion-datos :titulo="datos"  @cambios="guardar()" >
            <div class="form-group">
                <label for="nombre_completo">{{ $t('general.nombrecompleto') }}</label>
                <input type="text" class="form-control nombre_completo dato" v-model='nombre' id="nombre_completo" placeholder="Nombre completo" >
            </div>
            <div class="form-group">
                <label for="nif">NIF/NIE</label>
                <input type="text" class="form-control nif dato" v-model='nif' id="nif" placeholder="NIF/NIE" >
            </div>
            <div class="form-group">
                <label for="nif">{{ $t('general.direcciondeemail') }}</label>
                <input type="text" class="form-control email dato" v-model='email' id="email" placeholder="e-mail personal, usado para recuperación contraseña" autocomplete=false >
            </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: ['usuario','id'],
    data() {
        return {
            nombre: '',
            nif: '',
            email: '', 
            datos:this.$t("general.datos"),
        }
    },
    methods: {
        cargardatos() {
            this.$store.dispatch('cambiarCargando', true);
            this.nombre = this.usuario.nombre_completo;
            this.nif = this.usuario.nif;
            this.email = this.usuario.email;
            this.$store.dispatch('cambiarCargando', false);
            this.datos = this.$t("general.datos");

        },
        async guardar() {
            try {
                const api = new PwgsApi;
                const subidadatos = { nombre_completo: this.nombre, nif: this.nif, email: this.email };
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('usuarios/' + this.usuario.id, subidadatos);
                this.$parent.$parent.obtenerUsuario(this.usuario.id);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        },
        async crear() {
            try {
                const api = new PwgsApi;
                const subidadatos = { nombre: this.nombre, nif: this.nif, email: this.email };
                await api.put('usuarios', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        }, 
    },
    watch: {
        usuario() {
            this.cargardatos();
        }
    },
    mounted() {
        this.cargardatos();
    },
}
</script>